import { FeatureCollection, Point, Polygon } from 'geojson';
import { subDays } from 'date-fns';
import LatLon from 'geodesy/latlon-spherical';
import { CameraTimeLapseFrameDetails } from 'hooks/useAlertCameraPlayer';
import { AlertCamera, AlertCameraGroup } from 'hooks/useAlertCameras';
import {
  CameraArrow,
  CameraArrowOffline,
  CameraArrowSelected,
  CameraPTZBase,
  CameraPTZBaseOffline,
  CameraPTZBaseSelected,
  CameraStationaryBase,
  CameraStationaryBaseOffline,
  CameraStationaryBaseSelected,
} from '../../Icons';
import {
  CameraBaseFeatureProperties,
  CameraGeoJson,
  GenericCameraFeatureProperties,
} from './AlertCamerasLayer.types';

export const noop = (): void => {};

const getViewShedDistance = (fov: number): number => {
  // No FOV will be larger than 180 (the cams max out at ~63 deg).
  const scaleFactor = Math.pow(180 - fov, 2);
  const scaleConstant = 0.5;
  return scaleConstant * scaleFactor;
};

const isCameraOffline = (timestamp: Date): boolean =>
  timestamp < subDays(new Date(), 1);

export const getBaseCameraIcon = (
  cameraGroup: AlertCameraGroup,
  isSelected: boolean,
): string => {
  const { hasPtz } = cameraGroup;
  const isOffline = cameraGroup.cameras.every((camera) =>
    isCameraOffline(camera.imageTimestamp),
  );

  if (isSelected) {
    return hasPtz
      ? CameraPTZBaseSelected.name
      : CameraStationaryBaseSelected.name;
  }

  if (isOffline) {
    return hasPtz
      ? CameraPTZBaseOffline.name
      : CameraStationaryBaseOffline.name;
  }

  return hasPtz ? CameraPTZBase.name : CameraStationaryBase.name;
};

export const getCameraArrowIcon = (
  camera: AlertCamera,
  isSelected: boolean,
): string => {
  const isOffline = isCameraOffline(camera.imageTimestamp);

  if (isSelected) {
    return CameraArrowSelected.name;
  }

  if (isOffline) {
    return CameraArrowOffline.name;
  }

  return CameraArrow.name;
};

const getViewShedPolygonCoordinates = (
  alertCamera: AlertCamera,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null,
): Polygon['coordinates'] => {
  const pos = new LatLon(alertCamera.latlng.lat, alertCamera.latlng.lng);
  // If there is a timelapse frame, use the az and fov from that frame.
  const az = currentTimelapseFrame?.azDeg || alertCamera.azDeg;
  const fov = currentTimelapseFrame?.fovDeg || alertCamera.fovDeg;
  const numCurvePoints = 20; // number of points on the "end" of the viewshed.

  // Create a GeoJSON polygon that consists of the camera's location, two points
  // on either side of it's field-of-view a given distance D away.
  const d = getViewShedDistance(fov);

  const points = [];
  const increments = fov / (numCurvePoints - 1);
  for (let i = 0; i < numCurvePoints; i += 1) {
    const angle = az + fov / 2 - i * increments;
    const p = pos.destinationPoint(d, angle);
    points.push([p.lng, p.lat]);
  }

  // GeoJSON polygon outer rings are right-wound (points go clockwise).
  return [[[pos.lng, pos.lat], ...points, [pos.lng, pos.lat]]];
};

export const toGeojsonFeatures = (
  cameraGroup: AlertCameraGroup,
): { baseIcons: CameraGeoJson[]; arrowIcons: CameraGeoJson[] } => {
  const { lat, lng } = cameraGroup.latlng;
  const camerasHaveAzimuth = cameraGroup.cameras.some((camera) =>
    Boolean(camera.azDeg),
  );

  const childCameraNames = cameraGroup.cameras.reduce((names, camera) => {
    if (!names) return camera.name;
    if (names.includes(camera.name)) return names;
    return `${names}\n${camera.name}`;
  }, '');

  const baseIcon: CameraGeoJson = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
    properties: {
      id: cameraGroup.id,
      azDeg: 0,
      ...(!camerasHaveAzimuth && cameraGroup.cameras[0]),
      ...(camerasHaveAzimuth && {
        childCameraIds: cameraGroup.cameras.map((camera) => camera.id),
        childCameraNames,
        childCameraNamesLength: childCameraNames.split('\n').length,
      }),
      icon: getBaseCameraIcon(cameraGroup, false),
      iconFocused: getBaseCameraIcon(cameraGroup, true),
    },
  };

  if (!camerasHaveAzimuth) {
    return { baseIcons: [baseIcon], arrowIcons: [] };
  }

  const ptzArrowIcons: CameraGeoJson[] = cameraGroup.cameras.map(
    (alertCamera) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [alertCamera.latlng.lng, alertCamera.latlng.lat],
      },
      properties: {
        ...alertCamera,
        icon: getCameraArrowIcon(alertCamera, false),
        iconFocused: getCameraArrowIcon(alertCamera, true),
      },
    }),
  );

  return { baseIcons: [baseIcon], arrowIcons: ptzArrowIcons };
};

export const toGeojsonViewshedFeatures = (
  cameraGroup: AlertCameraGroup,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null,
): CameraGeoJson[] => {
  const camerasHaveAzimuth = cameraGroup.cameras.some((camera) =>
    Boolean(camera.azDeg),
  );
  if (!camerasHaveAzimuth) return [];

  const viewShedPolygons: CameraGeoJson[] = cameraGroup.cameras.map(
    (alertCamera) => ({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: getViewShedPolygonCoordinates(
          alertCamera,
          currentTimelapseFrame,
        ),
      },
      properties: {
        ...alertCamera,
      },
    }),
  );

  return [...viewShedPolygons];
};

export const getCameraIdFromFeature = (
  cameraProperties: GenericCameraFeatureProperties,
): string => {
  if ('childCameraIds' in cameraProperties) {
    const childIds = JSON.parse(
      // maplibre unfortunately stringifies nested objects/arrays
      cameraProperties.childCameraIds as unknown as string,
    );
    return childIds[0];
  }

  return cameraProperties.id!;
};

export const getCameraFromGroup = (
  cameraGroup: AlertCameraGroup,
  id: string,
): AlertCamera | undefined =>
  cameraGroup.cameras.find(
    (camera) => camera.id === id || camera.cameraGroupId === id,
  );

export const generateViewshedGeojson = (
  alertCameraGroups: AlertCameraGroup[],
  viewshedType: 'stationary' | 'ptz',
  activeCameraId?: string,
  hoveredCameraId?: string | null,
  currentTimelapseFrame?: CameraTimeLapseFrameDetails | null,
): FeatureCollection<Point | Polygon, GenericCameraFeatureProperties> => {
  // Give priority to the camera the user is hovering over, relative to the one
  // they clicked on.
  const viewshedCameraId = hoveredCameraId || activeCameraId;

  // Exit early if there's nothing to highlight
  if (!viewshedCameraId) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  // Get the camera group that has the viewshed
  const viewshedCameraGroup: AlertCameraGroup | undefined = alertCameraGroups
    // filter out any of the wrong camera types
    .filter((cameraGroup) =>
      viewshedType === 'stationary' ? !cameraGroup.hasPtz : cameraGroup.hasPtz,
    )
    // filter out camera groups that don't contain the camera ID we want
    .find((cameraGroup) => getCameraFromGroup(cameraGroup, viewshedCameraId));

  return {
    type: 'FeatureCollection',
    features: viewshedCameraGroup
      ? // If a hovered camera is active, don't pass on the timelapse frame even if
        // one is set. This is to prevent the viewshed from changing based on the
        // metadata of the timelapse frame for the hovered camera (which is different)
        // from the one playing timelapse.
        toGeojsonViewshedFeatures(
          viewshedCameraGroup,
          hoveredCameraId ? undefined : currentTimelapseFrame,
        )
      : [],
  };
};

const hasCameraFeatureChildCameraNames = (feature: CameraGeoJson): boolean => {
  return (
    'childCameraNames' in feature.properties &&
    typeof feature.properties.childCameraNames === 'string' &&
    feature.properties.childCameraNames !== ''
  );
};

const getFeatureWithChildCamerasKey = (feature: CameraGeoJson): string => {
  const [lng, lat] = feature.geometry.coordinates;
  return `${lat},${lng}`;
};

const mergeChildCameraNames = (
  existingNames: string,
  newNames: string,
): string => {
  return `${existingNames}\n${newNames}`;
};

const updateFeatureChildCameraNamesProperty = (
  feature: CameraGeoJson,
  newCameraNames: string,
): CameraGeoJson => {
  return {
    ...feature,
    properties: {
      ...feature.properties,
      childCameraNames: newCameraNames,
      childCameraNamesLength: newCameraNames.split('\n').length,
    },
  };
};

export const getAllCameraLabelsGeoJson = (
  features: CameraGeoJson[],
): FeatureCollection => {
  const parsedFeatures = features.reduce(
    (parsedFeats, feature) => {
      if (!hasCameraFeatureChildCameraNames(feature)) {
        return parsedFeats;
      }

      const key = getFeatureWithChildCamerasKey(feature);
      const existingFeature = parsedFeats[key];

      if (existingFeature) {
        const mergedNames = mergeChildCameraNames(
          (existingFeature.properties as CameraBaseFeatureProperties)
            .childCameraNames!,
          (feature.properties as CameraBaseFeatureProperties).childCameraNames!,
        );
        // eslint-disable-next-line no-param-reassign
        parsedFeats[key] = updateFeatureChildCameraNamesProperty(
          existingFeature,
          mergedNames,
        );
      } else {
        // eslint-disable-next-line no-param-reassign
        parsedFeats[key] = feature;
      }

      return parsedFeats;
    },
    {} as Record<string, CameraGeoJson>,
  );

  return {
    type: 'FeatureCollection',
    features: Object.values(parsedFeatures),
  };
};
