export const MIN_ZOOM_VISIBILITY_PTZ = 8;
export const MIN_ZOOM_VISIBILITY_STATIONARY = 10;
export const MAX_ZOOM_VISIBILITY_PUBLIC_USER = 15;
export const PTZ_SOURCE_ID = 'alert-cameras-ptz';
export const PTZ_ARROWS_SOURCE_ID = 'alert-cameras-ptz-arrows';
export const STATIONARY_SOURCE_ID = 'alert-cameras-stationary';
export const PTZ_ICONS_LAYER_ID = 'alert-camera-icons-ptz';
export const ALL_LABELS_SOURCE_ID = 'alert-cameras-all';
export const ALL_LABELS_LAYER_ID = 'alert-camera-all-labels';
export const PTZ_ARROW_ICONS_LAYER_ID = 'alert-camera-icons-ptz-arrows';
export const STATIONARY_ICONS_LAYER_ID = 'alert-camera-icons-stationary';
export const PTZ_VIEWSHED_LAYER_FILL_ID = 'alert-camera-viewshed-fill-ptz';
export const PTZ_VIEWSHED_LAYER_STROKE_ID = 'alert-camera-viewshed-stroke-ptz';
export const STATIONARY_VIEWSHED_SOURCE_ID =
  'alert-cameras-viewsheds-stationary';
export const PTZ_VIEWSHED_SOURCE_ID = 'alert-cameras-viewsheds-ptz';
export const STATIONARY_VIEWSHED_LAYER_FILL_ID =
  'alert-camera-viewshed-fill-stationary';
export const STATIONARY_VIEWSHED_LAYER_STROKE_ID =
  'alert-camera-viewshed-stroke-stationary';
export const VIEWSHED_FILL_OPACITY = 0;
export const VIEWSHED_STROKE_OPACITY = 0;
export const VIEWSHED_FILL_OPACITY_SELECTED = 0.33;
export const VIEWSHED_STROKE_OPACITY_SELECTED = 1;
