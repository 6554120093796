import {
  LayerProps,
  DataDrivenPropertyValueSpecification,
} from 'shared/map-exports';
import { FONT_NAMES } from 'components/Map/styles/constants';
import {
  MAX_ZOOM_VISIBILITY_PUBLIC_USER,
  MIN_ZOOM_VISIBILITY_PTZ,
  MIN_ZOOM_VISIBILITY_STATIONARY,
  VIEWSHED_FILL_OPACITY,
  VIEWSHED_FILL_OPACITY_SELECTED,
} from './AlertCamerasLayer.constants';
import { LabelTooltipBackground } from '../../Icons';

export const getIconImageLayout = (
  activeCameraId: string = '',
): DataDrivenPropertyValueSpecification<string> => {
  return [
    'match',
    ['get', 'id'],
    ['literal', activeCameraId],
    ['get', 'iconFocused'],
    ['get', 'icon'],
  ];
};

// All users get the same zoom levels for the icons, but non-internal users
// will have the icons hide at zoom levels above `MAX_ZOOM_VISIBILITY_PUBLIC_USER`.
const iconZoomExpression: DataDrivenPropertyValueSpecification<number> = [
  'interpolate',
  ['linear'],
  ['zoom'],
  7,
  0.3,
  11,
  0.5,
  13,
  0.6,
  15,
  0.75,
];

// Stationary / traffic cams get even bigger as the user zooms in, since they start
// off rather small.
const stationaryIconZoomExpression: DataDrivenPropertyValueSpecification<number> =
  [...iconZoomExpression, 17, 1.0];

export const getBaseIconStyles = (
  cameraType: 'stationary' | 'ptz',
  isInternalUser: boolean,
  activeCameraId?: string,
): LayerProps => ({
  type: 'symbol',
  minzoom:
    cameraType === 'ptz'
      ? MIN_ZOOM_VISIBILITY_PTZ
      : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser || cameraType === 'stationary'
    ? {}
    : { maxzoom: MAX_ZOOM_VISIBILITY_PUBLIC_USER }),
  layout: {
    'icon-image': getIconImageLayout(activeCameraId),
    'icon-allow-overlap': true,
    'icon-ignore-placement': false,
    'icon-size':
      cameraType === 'ptz' ? iconZoomExpression : stationaryIconZoomExpression,
  },
});

const commonLabelStyles: LayerProps = {
  type: 'symbol',
  minzoom: MIN_ZOOM_VISIBILITY_PTZ,
  layout: {
    'text-font': FONT_NAMES.regular,
    'text-field': ['get', 'childCameraNames'],
    'text-anchor': 'top',
    'text-justify': 'auto',
    'text-size': 12,
    'text-allow-overlap': ['step', ['zoom'], false, 11, true],
    'text-offset': [0, 2.5],
    // Icon properties for the background box
    'icon-image': LabelTooltipBackground.name,
    'icon-text-fit': 'both',
    'icon-allow-overlap': ['step', ['zoom'], false, 11, true],
    'icon-offset': [0, 4.5],
  },
  paint: {
    'icon-opacity': 1,
  },
};

export const labelFewNamesStyles: LayerProps = {
  ...commonLabelStyles,
  filter: ['<', ['get', 'childCameraNamesLength'], 5],
  layout: {
    ...commonLabelStyles.layout,
    'icon-text-fit-padding': [10, 10, 10, 10], // [top, right, bottom, left]
  },
};

export const labelManyNamesStyles: LayerProps = {
  ...commonLabelStyles,
  filter: ['>=', ['get', 'childCameraNamesLength'], 5],
  layout: {
    ...commonLabelStyles.layout,
    'icon-text-fit-padding': [10, 10, 20, 10], // [top, right, bottom, left]
  },
};

export const getArrowIconStyles = (
  cameraType: 'stationary' | 'ptz',
  isInternalUser: boolean,
  activeCameraId?: string,
): LayerProps => ({
  type: 'symbol',
  minzoom:
    cameraType === 'ptz'
      ? MIN_ZOOM_VISIBILITY_PTZ
      : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser ? {} : { maxzoom: MAX_ZOOM_VISIBILITY_PUBLIC_USER }),
  layout: {
    'icon-image': getIconImageLayout(activeCameraId),
    'icon-allow-overlap': true,
    'icon-anchor': 'bottom',
    'icon-ignore-placement': false,
    'icon-size': iconZoomExpression,
    'icon-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      ['literal', [0, -16]],
      13,
      ['literal', [0, -24]],
    ],
    'icon-rotate': ['get', 'azDeg'],
  },
});

export const getPaintOpacityForActiveId = (
  activeCameraId?: string,
): DataDrivenPropertyValueSpecification<number> | number =>
  activeCameraId
    ? [
        'case',
        ['==', ['get', 'id'], ['literal', activeCameraId]],
        VIEWSHED_FILL_OPACITY_SELECTED,
        VIEWSHED_FILL_OPACITY,
      ]
    : 0;

export const getViewshedFillStyles = (
  type: 'ptz' | 'stationary',
  isInternalUser: boolean,
  activeCameraId?: string,
): LayerProps => ({
  type: 'fill',
  layout: {},
  minzoom:
    type === 'ptz' ? MIN_ZOOM_VISIBILITY_PTZ : MIN_ZOOM_VISIBILITY_STATIONARY,
  ...(isInternalUser ? {} : { maxzoom: MAX_ZOOM_VISIBILITY_PUBLIC_USER }),
  paint: {
    'fill-color': '#3388FF',
    'fill-opacity': getPaintOpacityForActiveId(activeCameraId),
  },
});

export const getViewshedStrokeStyles = (
  type: 'ptz' | 'stationary',
  isInternalUser: boolean,
  activeCameraId?: string,
): LayerProps => ({
  type: 'line',
  layout: {},
  ...(isInternalUser ? {} : { maxzoom: MAX_ZOOM_VISIBILITY_PUBLIC_USER }),
  minzoom:
    type === 'ptz' ? MIN_ZOOM_VISIBILITY_PTZ : MIN_ZOOM_VISIBILITY_STATIONARY,
  paint: {
    'line-color': '#3388FF',
    'line-width': ['interpolate', ['linear'], ['zoom'], 8, 2, 18, 6],
    'line-opacity': getPaintOpacityForActiveId(activeCameraId),
  },
});
