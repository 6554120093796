import { useCallback, useEffect, useState, FC, useMemo, memo } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  MapComponent,
  Map as GLMap,
  MarkerComponent,
  useMap,
  LngLatBoundsLike,
  LngLat,
  LngLatBounds,
  FitBoundsOptions,
  ScaleControl,
  AttributionControl,
  ViewState,
  PointLike,
  LngLatBoundsFromLngLat,
} from 'shared/map-exports';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, useMediaQuery, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import usePrevious from 'hooks/usePrevious';
import usePosition from 'hooks/usePosition';
import { usePoisState } from 'state/usePoisState';
import useMapPlaces from 'hooks/useMapPlaces';
import { MapLocation } from 'shared/types';
import useMapLayersState from 'state/useMapLayersState';
import useWebGL2State from 'state/useWebGL2State';
import useSelectedLocationState from 'state/useSelectedLocationState';
import MapBoxLogo from 'assets/mapbox.svg?react';
import useSearchDrawerState from 'state/useSearchDrawerState';
import { useSelectedCameraId } from 'hooks/useSelectedCameraId';
import { useMapDrawerEntityState } from 'state/useMapDrawerEntityState';
import { useAllowMapLongPress } from 'hooks/useMapLongPress';
import {
  getOpacityForReportMarker,
  ReportIcon,
  ReportIconFov,
  CurrentLocationIcon,
  getGeoEventIcon,
  getGeoEventScale,
  getOpacityForGeoEventMarker,
  MapIconsWithRasters,
  PlaceLocationPinIcon,
  LocationPinIcon,
  getLocationIcon,
} from './Icons';
import LayersControl from './controls/LayersControl';
import {
  DEFAULT_LATLNG,
  LAYERS_DRAWER_WIDTH,
  LOCATION_ZOOM_LEVEL,
  MapBaseLayerStyles,
  MapLayers,
} from './constants';
import ActiveFirePerimetersLayer from './layers/ActiveFirePerimetersLayer';
import HistoricalFirePerimetersLayer from './layers/HistoricalFirePerimetersLayer';
import ViirsLayer from './layers/ViirsLayer';
import ModisLayer from './layers/ModisLayer';
import GeolocateControl from './controls/GeolocateControl';
import PurpleAirLayer from './layers/PurpleAirLayer';
import AttributionLayer from './layers/AttributionLayer';
import WindLayer from './layers/WindLayer';
import LayersDrawer from './controls/LayersDrawer';
import NavigationControl from './controls/NavigationControl';
import LegendControl from './controls/LegendControl';
import ReporterCoverageLayer from './layers/ReporterCoverageLayer';
import SearchBar from './SearchBar';
import LocationPermissionDialog from './LocationPermissionDialog';
import { PoiDialog } from './PoiDialog';
import { PlacesLayer } from './layers/PlacesLayer';
import AircraftsLayer from './layers/AircraftsLayer';
import RadioTowersLayer from './layers/RadioTowersLayer';
import LocationMarkerLabel from './LocationMarkerLabel';
import { hasWebGL2Support } from './utils';
import RedFlagWarningsLayer from './layers/RedFlagWarningsLayer';
import {
  MapProps,
  MarkersProps,
  ReportMarkerProps,
  MarkerProps,
  WildFireGeoEventMarkerProps,
  CenterViewProps,
  MapEventsProps,
  UserMarkerProps,
  PlaceMarkerProps,
  LocationMarkerProps,
  DefaultViewState,
} from './types';
import { useAuthState } from '../../state';
import {
  MAP_DOM_ELEMENT_ID,
  SEARCH_CARD_MIN_SNAP_PCT,
  SEARCH_DRAWER_WIDTH,
} from '../../constants';
import { useSelectedWildfireGeoEventId } from '../../hooks/useSelectedWildfireGeoEventId';
import { PowerOutageItemLayer } from './layers/PowerOutageItemLayer';
import { PowerOutageAreaLayer } from './layers/PowerOutageAreaLayer';
import { SupportUsDialog } from './SupportUsDialog';
import { LayersGroupDrawer } from './controls/LayersGroupDrawer';
import { MAP_ENTITY_DRAWER_WIDTH } from './MapEntityDrawer';
import ExternalGeoEventsLayer from './layers/ExternalGeoEventsLayer';
import FederalLandsLayer from './layers/FederalLandsLayer';
import ElectricRetailServiceLayer from './layers/ElectricRetailServiceLayer';
import ResponsibilityAreasLayer from './layers/ResponsibilityAreasLayer';
import { MapRenderBugDetector } from './MapRenderBugDetector';
import { StructuredEvacuationsAllLayer } from './layers/StructuredEvacuationsLayer';
import MapToolsControl from './controls/MapToolsControl';

declare global {
  interface Window {
    wdMapLoaded?: boolean;
  }
}

const useStyles = makeStyles()((theme) => ({
  content: {
    width: '100%',
    height: '100%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // We have 2 layer drawers, so we need to increase negative margin accordingly
    // to avoid overlapping the map even when they are closed (white space).
    marginRight: -LAYERS_DRAWER_WIDTH * 2,
    position: 'relative',
    '& .maplibregl-ctrl-top-right, & .maplibregl-ctrl-bottom-right': {
      transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    },
  },
  contentWithSideDrawerOpen: {
    [theme.breakpoints.up('tablet')]: {
      '& .maplibregl-ctrl-top-right, & .maplibregl-ctrl-bottom-right': {
        transform: `translateX(-${
          MAP_ENTITY_DRAWER_WIDTH - SEARCH_DRAWER_WIDTH
        }px)`,
      },
    },
  },
  contentWithSideDrawerClosed: {
    [theme.breakpoints.up('tablet')]: {
      '& .maplibregl-ctrl-top-right, & .maplibregl-ctrl-bottom-right': {
        transform: `translateX(-${MAP_ENTITY_DRAWER_WIDTH}px)`,
      },
    },
  },
  mobileContent: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  locationLabelContainer: {
    position: 'absolute',
    top: '100%',
    minWidth: 'max-content',
    maxWidth: 300,
  },
  placeIcon: {
    position: 'absolute',
    top: '19%',
    left: 'calc(50% - 8px)',
    color: 'white',
    path: 'white',
    width: 16,
    height: 16,
    '& path, rect': {
      fill: 'white',
    },
  },
  placeLabel: {
    textShadow:
      '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
    fontWeight: 600,
  },
  markerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    // Prevents a long-press on the marker image from opening the context menu on mobile devices.
    WebkitTouchCallout: 'none',
  },
}));

export const DEFAULT_VIEW_STATE: DefaultViewState = {
  latitude: DEFAULT_LATLNG.lat,
  longitude: DEFAULT_LATLNG.lng,
  zoom: 9,
  minZoom: 4,
  maxZoom: 18,

  // Set zIndex to 0 to create a stacking context for markers. Markers get zIndexes
  // (sometimes very large ones) to sort them visually. This stacking context ensures
  // that none of the markers are displayed above other UI elements outside the map
  // view.
  style: { width: '100%', height: '100%', zIndex: 0 },
};

const Map = (props: MapProps): JSX.Element => {
  const {
    children,
    // id cannot be 'default' if the map is a child map used within a mapProvider context. See: https://trello.com/c/qwh1L8gg
    id = 'default',
    center = DEFAULT_LATLNG,
    zoom = DEFAULT_VIEW_STATE.zoom,
    minZoom = DEFAULT_VIEW_STATE.minZoom,
    cursor = 'auto',
    style = {},
    disableMapLayers = [],
    noControls = false,
    searchEnabled = false,
    onViewportChange,
    interactive = true,
    withPlaces = true,
    zoomControlEnabled = false, // overwrites "noControls" prop for zoom control
    layersControlEnabled = false, // overwrites "noControls" prop for layers control
    onLocationChange,
    searchBarReturnLink,
    searchApi,
    onClick,
    onContextMenu,
    onTouchStart,
    bounds,
    fitBoundsOptions,
  } = props;
  const prevDisabledMapLayers = usePrevious(disableMapLayers);
  const userPosition = usePosition();
  const { classes } = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('phone'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('tablet'));
  const { state: locationState } = useLocation();
  // Use state to hold the map reference instead of useRef(). State allows
  // hooks below to be re-run when the map reference becomes available (or changes).
  const [map, setMap] = useState<GLMap | undefined>();
  const mapLayersState = useMapLayersState();
  const { mapBaseLayer, drawerOpen, setDrawerOpen } = mapLayersState;
  const mapStyle = MapBaseLayerStyles[mapBaseLayer];
  const { places } = useMapPlaces();
  const { selectedLocation, setSelectedLocation } = useSelectedLocationState();
  const { clearSelectedPois } = usePoisState();
  const {
    permissions: { isInternalUser },
    showMembershipProFeatures,
    showMembershipFeatures,
  } = useAuthState();
  const [cursorStyle, setCursorStyle] = useState(cursor);
  const selectedGeoEventId = useSelectedWildfireGeoEventId();
  const { open: searchDrawerOpen } = useSearchDrawerState();
  const selectedCameraId = useSelectedCameraId();
  const { mapDrawerEntity } = useMapDrawerEntityState();

  // initialize this hasWebGL2 globally into the state so downstate map components can check.
  //   Requires the map instance which we have here to compute.
  const { hasWebGL2, setEnabledData: setWebGL2Enabled } = useWebGL2State();

  useEffect(() => {
    if (hasWebGL2 !== undefined || !map) return;
    setWebGL2Enabled({ enabled: hasWebGL2Support(map) });
  }, [hasWebGL2, map, setWebGL2Enabled]);

  useEffect(() => {
    // Close drawer layer when the map is unmounted
    return () => {
      if (drawerOpen) {
        setDrawerOpen(false);
      }
    };
  }, [drawerOpen, setDrawerOpen]);

  useEffect(() => {
    // De-select any selected POI when the map is unmounted
    return () => {
      clearSelectedPois();
    };
  }, [clearSelectedPois]);

  useEffect(() => {
    if (prevDisabledMapLayers?.length !== disableMapLayers.length) {
      mapLayersState.setDisabledLayers(disableMapLayers);
    }
  }, [prevDisabledMapLayers, disableMapLayers, mapLayersState]);

  // Filter any map layers that are disabled.
  const mapLayers = mapLayersState.mapLayers.filter(
    (name) => !disableMapLayers.includes(name),
  );

  // Icons are unloaded from the map every time the map base layer changes.
  // Registering for 'styleimagemissing' events allows for calling map.addImage()
  // (so long as it is in the body of the callback!) to load missing icon images.
  const handleMissingStyleImage = useCallback(
    (event: { id: string }) => {
      if (!map) return;
      const icon = MapIconsWithRasters.find((i) => i.name === event.id);
      if (!icon) return;
      map.addImage(icon.name, icon.img, {
        pixelRatio: window.devicePixelRatio,
        // content, stretchX, and stretchY, if provided, give maplibre hints
        // about the bounding box that should be used to display content within
        // a symbol, and what pixel ranges are safe to stretch when resizing
        // the symbol.
        // @ts-expect-error property is specific to the maplibre-gl library
        content: icon.content,
        stretchX: icon.stretchX,
        stretchY: icon.stretchY,
      });
    },
    [map],
  );

  useEffect(() => {
    if (!map) return () => {};
    map.on('styleimagemissing', handleMissingStyleImage);
    return () => map.off('styleimagemissing', handleMissingStyleImage);
  }, [handleMissingStyleImage, map]);

  // Turn off rotation using touch gestures. There is no attribute on <MapComponent> to do this, only to
  // disable BOTH zooming and rotation using touch gestures.
  const handleOnLoad = useCallback(() => {
    if (!map) return;
    map.touchZoomRotate.disableRotation();
    map.setBearing(0);
    // Needed for e2e testing - allows us to wait until mas has been loaded
    window.wdMapLoaded = true;
  }, [map]);

  const handleLocationChange = useCallback(
    (location: MapLocation | null): void => {
      if (!location) {
        setSelectedLocation(null);
        return;
      }

      const { lat, lng, bbox, place } = location;

      if (!lat || !lng) return;

      if (onLocationChange) {
        onLocationChange(location);
        map?.easeTo({ center: [lng, lat] });
        return;
      }

      if (!place) setSelectedLocation(location);

      map?.easeTo({ center: [lng, lat], zoom: LOCATION_ZOOM_LEVEL });

      if (!bbox || !onViewportChange) return;

      // https://docs.mapbox.com/help/glossary/bounding-box/
      const southWest = new LngLat(bbox[0], bbox[1]);
      const northEast = new LngLat(bbox[2], bbox[3]);
      const boundingBox = new LngLatBounds(southWest, northEast);

      onViewportChange({
        center: { lat, lng },
        zoom: 6,
        bounds: boundingBox,
      });
    },
    [map, onLocationChange, onViewportChange, setSelectedLocation],
  );

  useEffect(() => {
    if (!map || !locationState) return;
    window.history.replaceState({}, document.title);
    handleLocationChange(locationState);
  }, [handleLocationChange, locationState, map]);

  const handleMouseDown = useCallback(() => {
    setCursorStyle('grab');
  }, []);

  const handleMouseUp = useCallback(() => {
    setCursorStyle(cursor);
  }, [cursor]);

  const getContainerClasses = (): string => {
    if (isPhone) {
      return classes.mobileContent;
    }

    if (selectedGeoEventId || selectedCameraId || mapDrawerEntity) {
      if (searchDrawerOpen) {
        return `${classes.content} ${classes.contentWithSideDrawerOpen}`;
      }
      return `${classes.content} ${classes.contentWithSideDrawerClosed}`;
    }

    return classes.content;
  };

  const isPlatformWeb = Capacitor.getPlatform() === 'web';

  const marginBottom = isDesktop
    ? 16
    : SEARCH_CARD_MIN_SNAP_PCT * window.innerHeight + 8;

  const initialViewState = useMemo(
    (): Partial<ViewState> & {
      bounds?: LngLatBoundsLike;
      fitBoundsOptions?: FitBoundsOptions;
    } => ({
      latitude: center.lat,
      longitude: center.lng,
      zoom,
      maxZoom: DEFAULT_VIEW_STATE.maxZoom,
      minZoom,
      // @ts-expect-error
      bounds,
      fitBoundsOptions,
    }),
    [bounds, center.lat, center.lng, fitBoundsOptions, minZoom, zoom],
  );

  const ccsStyle = useMemo(
    () => ({
      ...DEFAULT_VIEW_STATE.style,
      ...style,
    }),
    [style],
  );
  return (
    <>
      <Box className={getContainerClasses()} id={MAP_DOM_ELEMENT_ID}>
        <MapComponent
          ref={(ref) => setMap(ref?.getMap())}
          onLoad={handleOnLoad}
          mapStyle={mapStyle}
          style={ccsStyle}
          reuseMaps
          id={id}
          minZoom={minZoom}
          maxZoom={DEFAULT_VIEW_STATE.maxZoom}
          // @ts-expect-error
          initialViewState={initialViewState}
          dragRotate={false}
          touchPitch={false}
          attributionControl={false}
          cursor={cursorStyle}
          interactive={interactive}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onClick={onClick}
          onContextMenu={onContextMenu}
          onTouchStart={onTouchStart}
        >
          {/* See https://trello.com/c/kmVx4ZZS/1198-base-map-doesnt-always-load-blank-or-white */}
          <MapRenderBugDetector map={map} />
          <AttributionLayer />
          <MapBoxLogo
            style={{
              position: 'absolute',
              bottom: marginBottom + 1,
              left: 16,
            }}
          />
          {!noControls && (
            <>
              <ScaleControl
                unit="imperial"
                position="bottom-right"
                style={{ marginRight: 16, marginBottom }}
              />
              <AttributionControl
                compact
                customAttribution={[
                  '<a href="http://www.esri.com">ESRI</a>',
                  '<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                  '<a href="https://www.mapbox.com/">Mapbox</a>',
                ]}
                position="bottom-left"
                style={{ marginLeft: 105, marginBottom }}
              />
            </>
          )}
          {(!noControls || layersControlEnabled) && (
            <LayersControl
              position="top-right"
              setDrawerOpen={mapLayersState.setDrawerOpen}
            />
          )}
          {!noControls && (
            <>
              <GeolocateControl
                position="top-right"
                userPosition={userPosition}
              />
              <LegendControl
                position="top-right"
                setDrawerOpen={mapLayersState.setDrawerOpen}
              />
            </>
          )}
          {!noControls && showMembershipProFeatures && (
            <MapToolsControl position="top-right" />
          )}
          {isPlatformWeb && (!noControls || zoomControlEnabled) && (
            <NavigationControl position="top-right" />
          )}
          <ReporterCoverageLayer />
          {showMembershipProFeatures && (
            <StructuredEvacuationsAllLayer
              visible={mapLayers.includes(MapLayers.EVACUATION_ZONES)}
            />
          )}
          {showMembershipProFeatures && (
            <ElectricRetailServiceLayer
              visible={mapLayers.includes(MapLayers.ELECTRIC_RETAIL_SERVICE)}
            />
          )}
          <RedFlagWarningsLayer
            visible={mapLayers.includes(MapLayers.RED_FLAG_WARNINGS)}
          />
          <PowerOutageAreaLayer
            visible={mapLayers.includes(MapLayers.POWER_OUTAGES)}
          />
          <PowerOutageItemLayer
            visible={mapLayers.includes(MapLayers.POWER_OUTAGES)}
          />
          <HistoricalFirePerimetersLayer
            visible={mapLayers.includes(MapLayers.HISTORICAL_FIRE_PERIMETERS)}
          />
          {showMembershipProFeatures && (
            <ResponsibilityAreasLayer
              visible={mapLayers.includes(MapLayers.RESPONSIBILITY_AREAS)}
            />
          )}
          <ActiveFirePerimetersLayer
            visible={mapLayers.includes(MapLayers.ACTIVE_FIRE_PERIMETERS)}
          />
          <ViirsLayer visible={mapLayers.includes(MapLayers.VIIRS)} />
          <ModisLayer visible={mapLayers.includes(MapLayers.MODIS)} />
          <PurpleAirLayer visible={mapLayers.includes(MapLayers.PURPLE_AIR)} />
          {showMembershipProFeatures && (
            <>
              <FederalLandsLayer
                visible={mapLayers.includes(MapLayers.FEDERAL_STATE_LOCAL)}
              />
              <RadioTowersLayer
                visible={mapLayers.includes(MapLayers.RADIO_TOWERS)}
              />
            </>
          )}
          <WindLayer visible={mapLayers.includes(MapLayers.SURFACE_WIND)} />
          {showMembershipFeatures && (
            <AircraftsLayer
              visible={mapLayers.includes(MapLayers.FLIGHT_TRACKER)}
            />
          )}
          {isInternalUser && (
            <ExternalGeoEventsLayer
              visible={mapLayers.includes(MapLayers.EXTERNAL_GEO_EVENTS)}
            />
          )}
          {userPosition.locationEnabled &&
            userPosition?.lat &&
            userPosition?.lng && (
              <UserMarker
                lat={userPosition?.lat}
                lng={userPosition?.lng}
                heading={userPosition?.az}
              />
            )}
          {selectedLocation?.lat && selectedLocation?.lng && (
            <PlaceMarker
              lat={selectedLocation.lat}
              lng={selectedLocation.lng}
              name={selectedLocation.name}
            />
          )}
          {withPlaces && <PlacesLayer places={places} />}
          {children}
          <PoiDialog />
          {!noControls && <SupportUsDialog />}
        </MapComponent>
        {searchEnabled && (
          <SearchBar
            onLocationChange={handleLocationChange}
            includeGeoEvents
            returnLink={searchBarReturnLink}
            searchApi={searchApi}
          />
        )}
      </Box>
      <LayersDrawer />
      <LayersGroupDrawer />
      <LocationPermissionDialog
        open={userPosition.askForPermission}
        onClose={() => userPosition.setAskForPermission(false)}
      />
    </>
  );
};

const Markers = (props: MarkersProps): JSX.Element | null => {
  const { locations, type, isFadable } = props;
  const history = useHistory();
  const { pathname } = useLocation();

  if (!locations) {
    return null;
  }

  const locationsArray = Array.isArray(locations) ? locations : [locations];

  const handleClickReport = (modelId: number, reportId: number): void => {
    const prefix = pathname.includes('incident') ? 'incident' : 'i';
    // @todo we should have a better route for reports without incidents than /null/: https://trello.com/c/6vzfqxkQ/278-create-route-for-reports-without-incidents
    history.push(`/${prefix}/${modelId}/reports/${reportId}`);
  };

  const markers = locationsArray.reduce((items, location) => {
    if (type === 'report' || type === 'media') {
      items.push(
        <ReportMarker
          key={location.id}
          {...location}
          isFadable={isFadable}
          onClick={() => handleClickReport(location.geoEventId, location.id)}
        />,
      );
    }

    return items;
  }, [] as JSX.Element[]);

  return <>{markers}</>;
};

export const Marker = (props: MarkerProps): JSX.Element => {
  const {
    icon,
    onClick,
    opacity = 1,
    position,
    anchor = 'center',
    rotation = 0,
    scale = 1,
    draggable,
    onDrag,
    onDragStart,
    onDragEnd,
    zIndex,
    label,
  } = props;
  const { classes } = useStyles();
  const { lat, lng } = position;
  const setAllowLongPress = useAllowMapLongPress();

  const isClickable = Boolean(onClick);
  const className = isClickable ? undefined : 'default-cursor';

  const iconScale = icon.scale || 1.0;
  const finalScale = scale * iconScale;
  const offset: PointLike | undefined = icon.offset
    ? [icon.offset[0] * finalScale, icon.offset[1] * finalScale]
    : undefined;

  const imgWidth = icon.width * finalScale;
  const imgHeight = icon.height * finalScale;

  const handleClick = (): void => {
    if (isClickable && onClick) {
      onClick();
    }
  };

  return (
    <MarkerComponent
      latitude={lat}
      longitude={lng}
      anchor={anchor}
      rotation={rotation}
      onClick={handleClick}
      offset={offset}
      draggable={draggable}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      style={{ cursor: onClick ? 'pointer' : undefined, zIndex }}
    >
      <Box className={classes.markerContainer}>
        <div
          // Disable long press listener for the map while the user
          // is interacting with this marker.
          onTouchStart={() => draggable && setAllowLongPress(false)}
          onTouchEnd={() => draggable && setAllowLongPress(true)}
        >
          <Box sx={{ position: 'relative' }}>
            <img
              src={icon.data}
              className={className}
              alt="marker icon"
              style={{
                opacity,
                width: imgWidth,
                height: imgHeight,
              }}
            />
          </Box>
          {!!label && (
            <div className={classes.locationLabelContainer}>
              {typeof label === 'string' ? (
                <Typography color="textPrimary" className={classes.placeLabel}>
                  {label}
                </Typography>
              ) : (
                label
              )}
            </div>
          )}
        </div>
      </Box>
    </MarkerComponent>
  );
};

const ReportMarker = (props: ReportMarkerProps): JSX.Element | null => {
  const {
    dateCreated,
    id,
    isFadable = true,
    lat: latOverride, // from incident report
    lng: lngOverride, // from incident report
    media,
    onClick = () => {},
    az: reportAz, // from incident report
    overrideCoords = false,
  } = props;

  // media's lat and lng are preferred but the marker's lat and lng default to the incident report lat/lng

  const lat =
    overrideCoords && latOverride
      ? latOverride
      : (media && Array.isArray(media) && media[0] && media[0].lat) ||
        (media && !Array.isArray(media) && media.lat) ||
        latOverride ||
        null;

  const lng =
    overrideCoords && lngOverride
      ? lngOverride
      : (media && Array.isArray(media) && media[0] && media[0].lng) ||
        (media && !Array.isArray(media) && media[0] && media.lng) ||
        lngOverride ||
        null;

  const az = reportAz || media?.[0]?.az || 0;

  const opacity = getOpacityForReportMarker(dateCreated, isFadable);

  if (!lat || !lng) {
    return null;
  }

  return (
    <>
      {az > 0 && (
        <Marker
          icon={ReportIconFov}
          key={`report-fov-${id}-${lat}-${lng}`}
          opacity={opacity}
          position={{ lat, lng }}
          rotation={az}
          scale={1.9}
          zIndex={0}
        />
      )}

      <Marker
        icon={ReportIcon}
        key={`report-${lat}-${lng}`}
        onClick={onClick}
        opacity={opacity}
        position={{ lat, lng }}
        scale={1.3}
        zIndex={2}
      />
    </>
  );
};

export const WildFireGeoEventMarker = (
  props: WildFireGeoEventMarkerProps,
): JSX.Element | null => {
  const {
    anchor = 'bottom',
    isFadable = false,
    geoEvent,
    draggable,
    onDrag,
    onDragEnd,
  } = props;
  const { lat, lng } = geoEvent;

  if (!lat || !lng) {
    return null;
  }

  const scale = getGeoEventScale(geoEvent);
  const opacity = getOpacityForGeoEventMarker(geoEvent, isFadable);

  return (
    <Marker
      zIndex={9}
      position={{ lat, lng }}
      opacity={opacity}
      anchor={anchor}
      icon={getGeoEventIcon(geoEvent)}
      scale={scale}
      draggable={draggable}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    />
  );
};

const CenterView = (props: CenterViewProps): null => {
  const { locations, bottomOffset } = props;
  const { current: map } = useMap();

  useEffect(() => {
    if (locations.length === 0) return;

    const coordinates = locations
      .map((location) => {
        const { media } = location;
        const lat =
          location.lat ||
          (media && Array.isArray(media) && media[0] && media[0].lat) ||
          (media && !Array.isArray(media) && media.lat) ||
          null;
        const lng =
          location.lng ||
          (media && Array.isArray(media) && media[0] && media[0].lng) ||
          (media && !Array.isArray(media) && media[0] && media.lng) ||
          null;

        // Check if lat and lng are indeed numbers - !isNaN(x)
        if (lat !== null && !isNaN(lat) && lng !== null && !isNaN(lng)) {
          return new LngLat(lng, lat);
        }

        return null;
      })
      .filter(Boolean) as LngLat[];

    const bounds = coordinates.reduce(
      (b, coord) => b.extend(coord),
      LngLatBoundsFromLngLat(coordinates[0]),
    );
    if (!bounds.isEmpty()) {
      const options: FitBoundsOptions = {
        padding: 8,
        maxZoom: 12,
        animate: false,
      };
      if (bottomOffset) {
        options.offset = [0, bottomOffset];
      }
      map?.fitBounds(bounds, options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(locations), map]);

  return null;
};

// Updating map state on zoom and map move events
const MapEvents = (props: MapEventsProps): null => {
  const { onViewportChange } = props;
  const { current: map } = useMap();

  useEffect(() => {
    const onmove = (): void => {
      const center = map?.getCenter();
      const zoom = map?.getZoom();
      onViewportChange &&
        onViewportChange({
          center,
          zoom,
          bounds: map?.getBounds() || undefined,
        });
    };

    const onMoveInit = (): void => {
      onViewportChange &&
        onViewportChange({
          center: map?.getCenter(),
          zoom: map?.getZoom(),
          bounds: map?.getBounds() || undefined,
        });
    };

    map?.on('moveend', onmove);
    map?.on('load', onMoveInit);

    return () => {
      map?.off('moveend', onmove);
      map?.off('load', onMoveInit);
    };
  }, [map, onViewportChange]);
  return null;
};

const UserMarker = (props: UserMarkerProps): JSX.Element => {
  const { lat, lng, heading } = props;

  return (
    <>
      <Marker
        position={{ lat, lng }}
        icon={CurrentLocationIcon}
        rotation={heading}
        scale={0.6}
        zIndex={2}
      />
    </>
  );
};

const PlaceMarker = (props: PlaceMarkerProps): JSX.Element => {
  const { id, lat, lng, name, placeIcon, onClick } = props;

  const icon = placeIcon ? PlaceLocationPinIcon : LocationPinIcon;

  const getLabel = (): string | JSX.Element | undefined => {
    if (id) {
      return undefined; // Saved places labels are rendered by mapbox gl
    }
    if (!placeIcon) {
      return <LocationMarkerLabel label={name} />;
    }
    return name;
  };

  return (
    <Marker
      position={{ lat, lng }}
      icon={icon}
      scale={1}
      zIndex={3}
      label={getLabel()}
      onClick={onClick}
    />
  );
};

const LocationMarker = (props: LocationMarkerProps): JSX.Element | null => {
  const { location, draggable, onDrag, onDragEnd, scale } = props;
  const { lat, lng } = location;

  if (!lat || !lng || !location.data?.locationType) {
    return null;
  }

  return (
    <Marker
      zIndex={9}
      scale={scale}
      position={{ lat, lng }}
      anchor="bottom"
      icon={getLocationIcon(location.data.locationType)}
      draggable={draggable}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    />
  );
};

const MemoizedMap = memo(Map) as unknown as FC<MapProps> & {
  Marker: (props: MarkerProps) => JSX.Element;
  Markers: (props: MarkersProps) => JSX.Element | null;
  ReportMarker: (props: ReportMarkerProps) => JSX.Element | null;
  WildFireGeoEventMarker: (
    props: WildFireGeoEventMarkerProps,
  ) => JSX.Element | null;
  CenterView: (props: CenterViewProps) => null;
  MapEvents: (props: MapEventsProps) => null;
  PlaceMarker: (props: PlaceMarkerProps) => JSX.Element;
  LocationMarker: (props: LocationMarkerProps) => JSX.Element | null;
  DefaultConfig: DefaultViewState & {
    center: { lat: number; lng: number };
  };
};

MemoizedMap.Marker = Marker;
MemoizedMap.Markers = Markers;
MemoizedMap.ReportMarker = ReportMarker;
MemoizedMap.WildFireGeoEventMarker = WildFireGeoEventMarker;
MemoizedMap.CenterView = CenterView;
MemoizedMap.MapEvents = MapEvents;
MemoizedMap.PlaceMarker = PlaceMarker;
MemoizedMap.LocationMarker = LocationMarker;

// Export for compatibility.
MemoizedMap.DefaultConfig = {
  ...DEFAULT_VIEW_STATE,
  center: DEFAULT_LATLNG,
};

export default MemoizedMap;
