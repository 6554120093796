import { Theme } from '@mui/material';
import {
  Map,
  LayerProps,
  ExpressionSpecification,
  MapRef,
} from 'shared/map-exports';
import { Capacitor } from '@capacitor/core';
import { CSSObject } from 'tss-react/types';
import { SEARCH_CARD_MIN_SNAP_PCT, SEARCH_DRAWER_WIDTH } from '../../constants';
import {
  MAP_ENTITY_DRAWER_WIDTH,
  MAP_ENTITY_DRAWER_MIN_SNAP_PCT,
} from './MapEntityDrawer';
import { PoiType, SelectedEntityType } from '../../shared/types';
import { MapLayers, MapLayerDetail } from './constants';

type StyleFunctionProps = {
  idKey: string | ExpressionSpecification;
  otherLayerProps?: Partial<LayerProps>;
  selectedId: number | string;
};

const isWebGL2 = (
  context: WebGLRenderingContext | WebGL2RenderingContext,
): context is WebGL2RenderingContext => {
  return (
    'WebGL2RenderingContext' in window &&
    context instanceof WebGL2RenderingContext
  );
};

export const hasWebGL2Support = (map: Map): boolean => {
  if (!map.painter) return false;
  const {
    painter: {
      context: { gl },
    },
  } = map;
  try {
    if (!gl.getParameter(gl.VERSION).includes('WebGL 2.0')) return false;

    if (!isWebGL2(gl)) return false;

    // Check for specific WebGL 2.0 features
    const features = [
      // Transform Feedback
      'createTransformFeedback' in gl && 'bindTransformFeedback' in gl,
      // Vertex Array Objects
      'createVertexArray' in gl && 'bindVertexArray' in gl,
      // Instanced rendering
      'drawArraysInstanced' in gl && 'drawElementsInstanced' in gl,
      // Uniform Buffer Objects
      'createBuffer' in gl && 'bindBufferBase' in gl,
      // Check for some WebGL 2.0 specific constants
      'TRANSFORM_FEEDBACK_BUFFER' in gl,
      'UNIFORM_BUFFER' in gl,
    ];

    // Check if all features are supported
    return features.every((feature) => feature === true);
  } catch (e) {
    return false;
  }
};

export const getSelectedPatternStyle = (
  props: StyleFunctionProps,
): LayerProps => {
  const { idKey, otherLayerProps = {}, selectedId } = props;

  return {
    ...otherLayerProps,
    type: 'fill',
    paint: {
      'fill-pattern': 'SelectedPolygonPattern',
      ...(otherLayerProps.paint ? otherLayerProps.paint : {}),
    },
    filter: ['==', idKey, selectedId],
  };
};

export const getSelectedStrokeStyle = (
  props: StyleFunctionProps,
): LayerProps => {
  const { idKey, otherLayerProps = {}, selectedId } = props;
  return {
    ...otherLayerProps,
    type: 'line',
    paint: {
      'line-color': '#52E8EB',
      'line-opacity': 1,
      'line-width': ['interpolate', ['linear'], ['zoom'], 8, 1.25, 18, 3.25],
    },
    filter: ['==', idKey, selectedId],
  };
};

export const getMapDialogCommonStyles = (
  theme: Theme,
  options: {
    isGeoEventSelected: boolean;
    /** blocks map events to avoid accidental clicks that lead to map pin placements */
    interactive: boolean;
    minWidth: number;
  },
): Record<'root' | 'container' | 'paper', CSSObject> => {
  const { isGeoEventSelected, interactive, minWidth } = options;
  // Magic numbers - empiric tests led to them.
  const searchBottomOffset =
    (window.innerHeight * SEARCH_CARD_MIN_SNAP_PCT) / 2 + 20;
  const geoEventBottomOffset =
    (window.innerHeight * MAP_ENTITY_DRAWER_MIN_SNAP_PCT) / 2 + 10;
  return {
    root: {
      position: 'absolute !important' as 'absolute',
      width: '100%',
      inset: 'unset !important',
      left: '0px !important',
      bottom: '0px !important',
      pointerEvents: interactive ? 'auto' : 'none',
      [theme.breakpoints.up('md')]: {
        transform: isGeoEventSelected
          ? `translateX(-${
              (MAP_ENTITY_DRAWER_WIDTH - SEARCH_DRAWER_WIDTH) / 2
            }px)`
          : 'translateX(0)',
        transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      },
      [theme.breakpoints.down('tablet')]: {
        bottom: `calc(${searchBottomOffset}px - env(safe-area-inset-bottom) / 1.5) !important`,
        transform: isGeoEventSelected
          ? `translateY(calc(-${geoEventBottomOffset}px - env(safe-area-inset-bottom) / 15))`
          : `translateY(0)`,
        transition: 'transform 1s cubic-bezier(0, 0, 0.2, 1) 0ms',
      },
    },
    container: {
      width: '100%',
      [theme.breakpoints.down(480)]: {
        minWidth: 'unset',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
      },
    },
    paper: {
      margin: theme.spacing(0, 2),
      width: 'fit-content',
      maxHeight: 'max-content',
      minWidth,
      borderRadius: 8,
      display: 'inline-block',
      marginBottom: Capacitor.isNativePlatform()
        ? 'calc(30px + env(safe-area-inset-bottom))'
        : 48,
      [theme.breakpoints.down(480)]: {
        width: '100%',
        minWidth: 'unset',
      },
    },
  };
};

export const layerNameToPoiType: Record<string, PoiType> = {
  FlightTracker: 'aircraft',
  PowerOutages: 'powerOutage',
  RedFlagWarnings: 'rfw',
  EvacuationZones: 'evacZone',
  ExternalGeoEvents: 'externalGeoEvent',
  ResponsibilityAreas: 'responsibilityArea',
  ElectricalLines: 'electricalLine',
  GasPipelines: 'gasPipeline',
};

export const layerNameToEntityType: Record<string, SelectedEntityType> = {
  [MapLayers.PRIVATE_LAND_OWNERSHIP]: 'privateLandOwnership',
};

export const getMapZoomInteger = (map?: MapRef): number | undefined => {
  if (!map) return undefined;
  return Math.floor(map.getZoom());
};

export const canUserAccessMapLayer = (input: {
  layerDetails: MapLayerDetail;
  showMembershipFeatures: boolean;
  showMembershipProFeatures: boolean;
}): boolean => {
  const { layerDetails, showMembershipFeatures, showMembershipProFeatures } =
    input;

  if (layerDetails.minMembership === 'regular') {
    return showMembershipFeatures;
  }

  if (layerDetails.minMembership === 'pro') {
    return showMembershipProFeatures;
  }

  return true;
};
