import { Capacitor } from '@capacitor/core';
import { yupResolver } from '@hookform/resolvers/yup';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Alert,
  AlertTitle,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'api';
import GeoEventChangelog from 'components/GeoEventChangelog';
import GeoEventEvacuations from 'components/GeoEventEvacuations';
import { LoadingAndErrors } from 'components/LoadingAndErrors';
import RichTextEditor from 'components/RichTextEditor';
import { ClipboardEventHandler, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'shared/map-exports';
import { useHistory } from 'react-router-dom';
import { GeoEvent, NotificationType, UploadImageData } from 'shared/types';
import {
  getEvacZoneStyleFromGeoEvent,
  removeHTMLTags,
  uploadImage,
} from 'shared/utils';
import { useSnackbarState } from 'state';
import { makeStyles } from 'tss-react/mui';
import { InternalNotesInput } from 'components/InternalNotesInput';
import { useGeoEventEvacZones } from 'hooks/useGeoEventEvacZones';
import { MediaForm } from 'components/MediaForm';
import { FieldHelperMessages } from 'components/FieldHelperMessages';
import { NotificationType as NotificationTypeConstant } from '../../../constants';
import {
  getRedundancyMessage,
  getValuesForServer,
  getFormValidationSchema,
  getInitialFormValues,
  validateReport,
  invalidateQueryCache,
} from './utils';
import { ReportNotificationPreview } from './ReportNotificationPreview';
import { statusOptions } from './constants';
import { FormValues, PostReportData } from './types';
import { useGeoEvent } from '../../../hooks/useGeoEvent';
import { ReportMap } from './ReportMap';

type ReportFormProps = {
  geoEvent: GeoEvent;
};

const postReport = async (
  data: PostReportData,
  skipRedundancyCheck?: boolean,
): Promise<void> => {
  let url = '/reports';
  if (skipRedundancyCheck) {
    url += '/?skip_redundancy_check=true';
  }
  await API.post(url, data);
};

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  formContainer: {
    overflowY: 'auto',
    height: '100%',
    width: '100%',
    maxWidth: 720,
  },
  form: {
    paddingTop: theme.spacing(2),
    paddingBottom: 'max(env(safe-area-inset-bottom), 16px)',
  },
  notificationLabel: {
    textTransform: 'capitalize',
  },
  evacButton: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(-1),
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    height: 48,
  },
}));

const ReportForm = (props: ReportFormProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [postError, setPostError] = useState(false);
  const { setSnackbar } = useSnackbarState();
  const history = useHistory();
  const theme = useTheme();
  const isLargeMediaQuery = useMediaQuery(theme.breakpoints.up('laptop'), {
    defaultMatches: !Capacitor.isNativePlatform(),
  });
  const queryClient = useQueryClient();

  const cacheBuster = useMemo(() => Date.now(), []);

  const { geoEventEvacZones: availableZones } = useGeoEventEvacZones(geoEvent);

  const evacZoneStyle = getEvacZoneStyleFromGeoEvent(geoEvent);

  const hasEvacZones = availableZones.length > 0;
  const validationSchema = getFormValidationSchema(t, hasEvacZones);
  const initialValues = getInitialFormValues(geoEvent);

  const { saveGeoEvent } = useGeoEvent({ id: geoEvent?.id });

  const formMethods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onBlur',
  });

  const {
    control,
    formState,
    setValue,
    getValues,
    handleSubmit,
    watch,
    reset,
  } = formMethods;

  const { isPrescribed } = geoEvent.data;

  // GeoEvent changed (we probably fetched an updated version). Since hook-form
  // doesn't see updates to initialValues, we need to reset the form manually.
  useEffect(() => {
    reset(getInitialFormValues(geoEvent));
  }, [reset, geoEvent, getValues]);

  const s3AuthQuery = useQuery({
    queryKey: ['gets3Auth'],
    queryFn: () => API.get('media/storage_auth/'),
  });

  const uploadImageMutation = useMutation({
    mutationFn: (data: UploadImageData) => uploadImage(data),
  });

  const postReportMutation = useMutation({
    mutationFn: (update: {
      data: PostReportData;
      skipRedundancyCheck?: boolean;
    }) => postReport(update.data, update.skipRedundancyCheck),
  });

  const goBack = (geoEventId: number): void => {
    // We handle the goback differently for reporters so we force a full page
    // refresh with history.push.
    history.push(`/i/${geoEventId}`);
  };

  const handleSubmitReport: SubmitHandler<FormValues> = async (values) => {
    const { reportData, geoEventData } = getValuesForServer({
      currentGeoEvent: geoEvent,
      updates: values,
      hasEvacZones,
    });

    try {
      if (
        values.media &&
        'type' in values.media &&
        reportData.media[0]?.url &&
        s3AuthQuery.data
      ) {
        try {
          await uploadImageMutation.mutateAsync({
            media: values.media,
            s3AuthData: s3AuthQuery.data,
            s3Key: reportData.media[0].url,
          });
        } catch (e) {
          setSnackbar(t('addIncidentReport.imageUploadError'), 'error');
          return;
        }
      }

      await postReportMutation.mutateAsync({ data: reportData });

      await saveGeoEvent(geoEventData, geoEvent);

      await invalidateQueryCache(queryClient, geoEvent.id);

      setSnackbar(t('addIncidentReport.successMessage'), 'success');
      goBack(geoEvent.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorStatus = error?.response?.status;

      if (errorStatus === 403) {
        setPostError(true);
      } else if (errorStatus === 409) {
        // We should check for redundant posts (this only affects CIOs)
        // If there are too many posts in the last few minutes, we should warn them
        // `confirm` is super ghetto but its only seen by CIO's
        // eslint-disable-next-line
        const r = confirm(getRedundancyMessage(error.response));

        if (!r) return;

        try {
          await postReportMutation.mutateAsync({
            data: reportData,
            skipRedundancyCheck: true,
          });

          await saveGeoEvent(geoEventData, geoEvent);

          await invalidateQueryCache(queryClient, geoEvent.id);

          setSnackbar(t('addIncidentReport.successMessage'), 'success');
          goBack(geoEvent.id);
        } catch (finalError) {
          setSnackbar(t('common.unknownErrorTryAgain'), 'error');
        }
      } else {
        setSnackbar(t('common.unknownErrorTryAgain'), 'error');
      }
    }
  };

  const [asset, isFps, activeEvacuations, notificationType] = watch([
    'asset',
    'isFps',
    'activeEvacuations',
    'notificationType',
  ]);

  const handlePaste: ClipboardEventHandler<HTMLFormElement> = (event) => {
    /**
     * This handles copy-paste on the web.  In order to use,
     * the user must first click on the page to paste
     */
    if (Capacitor.getPlatform() !== 'web' || asset !== 'image') return;

    const clipboardItems = event.clipboardData.items;

    const items: DataTransferItem[] = [].slice
      .call(clipboardItems)
      .filter((item: DataTransferItem) => item.type.indexOf('image') !== -1);

    if (items.length === 0) {
      return;
    }

    const item = items[0];
    // Get the blob of image
    const blob = item.getAsFile();

    setValue('media', blob);
  };

  if (s3AuthQuery.isLoading) {
    return <LoadingAndErrors isLoading />;
  }

  return (
    <MapProvider>
      <FormProvider {...formMethods}>
        <Box
          className={classes.root}
          sx={{
            flexDirection: isLargeMediaQuery ? 'row' : 'column',
            overflow: isLargeMediaQuery ? 'hidden' : 'auto',
          }}
        >
          <Box
            sx={{
              flex: 1,
              minHeight: isLargeMediaQuery ? '100%' : 314,
              display: 'flex',
            }}
          >
            <ReportMap
              geoEvent={geoEvent}
              zoomControlEnabled={isLargeMediaQuery}
              cacheBuster={cacheBuster}
            />
          </Box>
          <Container
            maxWidth={false}
            className={isLargeMediaQuery ? classes.formContainer : undefined}
          >
            <form
              onSubmit={handleSubmit(handleSubmitReport)}
              noValidate
              className={classes.form}
              onPaste={handlePaste}
            >
              <Grid container spacing={2}>
                {postError && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      <AlertTitle>
                        {t('addIncidentReport.postError.title')}
                      </AlertTitle>
                      <Typography>
                        {t('addIncidentReport.postError.description')}
                      </Typography>
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.incident')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="geoEventId"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-incident"
                          label={t('addIncidentReport.inputs.incident.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled
                        >
                          <MenuItem
                            value={geoEvent.id}
                            className={classes.notificationLabel}
                          >
                            {geoEvent.name}
                          </MenuItem>
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-status"
                          label={t('addIncidentReport.inputs.status.label')}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled={formState.isSubmitting}
                        >
                          {statusOptions.map(({ label, value }) => {
                            return (
                              <MenuItem
                                key={label}
                                value={value}
                                className={classes.notificationLabel}
                              >
                                {label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name="acreage"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-acreage"
                              label={t(
                                'addIncidentReport.inputs.acreage.label',
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : null,
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'decimal',
                                onWheel: (e) => e.currentTarget.blur(),
                              }}
                              disabled={formState.isSubmitting}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="containment"
                        control={control}
                        render={({ field, fieldState }): JSX.Element => {
                          const { ref, value, onChange, ...muiFieldProps } =
                            field;
                          return (
                            <TextField
                              id="field-control-containment"
                              label={t(
                                'addIncidentReport.inputs.containment.label',
                              )}
                              fullWidth
                              {...muiFieldProps}
                              value={value ?? ''}
                              onChange={(e) =>
                                onChange(
                                  e.target.value
                                    ? parseInt(e.target.value, 10)
                                    : null,
                                )
                              }
                              inputRef={ref}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              type="number"
                              inputProps={{
                                pattern: '[0-9]+([.,][0-9]+)?',
                                inputMode: 'numeric',
                                onWheel: (e) => e.currentTarget.blur(),
                              }}
                              disabled={
                                formState.isSubmitting || !!isPrescribed
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="isFps"
                    control={control}
                    render={({ field }): JSX.Element => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="isPrescribed"
                              checked={field.value}
                              onChange={(event) =>
                                field.onChange(event.target.checked)
                              }
                            />
                          }
                          label={t('addIncidentReport.forwardProgressStopped')}
                          disabled={formState.isSubmitting || !!isPrescribed}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="activeEvacuations"
                    control={control}
                    render={({ field }): JSX.Element => {
                      return (
                        <Button
                          variant="text"
                          className={classes.evacButton}
                          startIcon={
                            activeEvacuations ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <ChevronRightIcon />
                            )
                          }
                          onClick={() => field.onChange(!field.value)}
                          disabled={formState.isSubmitting}
                        >
                          {t('addIncidentReport.activeEvacuations')}
                        </Button>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* Ideally we would use MUI's <Collapse /> here, but there
                  are some performance problems with iOS Safari:
                  https://trello.com/c/mc3v3rWy/1045-slowdown-on-certain-add-report-pages */}
                  <Box display={activeEvacuations ? 'block' : 'none'}>
                    <GeoEventEvacuations
                      evacZones={availableZones}
                      zoneStyle={evacZoneStyle}
                    />
                  </Box>
                </Grid>

                <InternalNotesInput
                  initialValue={initialValues.reporterOnlyNotes}
                />

                <Grid item xs={12}>
                  <Typography variant="h3">
                    {t('addIncidentReport.sections.update')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="notificationType"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const { ref, ...muiFieldProps } = field;
                      return (
                        <TextField
                          select
                          id="field-control-notificationType"
                          label={t(
                            'addIncidentReport.inputs.notificationType.label',
                          )}
                          fullWidth
                          {...muiFieldProps}
                          inputRef={ref}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          required
                          className={classes.notificationLabel}
                          disabled={
                            geoEvent.notificationType ===
                              NotificationType.Silent || formState.isSubmitting
                          }
                        >
                          {Object.values(NotificationTypeConstant).map(
                            (notifType) => {
                              return (
                                <MenuItem
                                  key={notifType}
                                  value={notifType}
                                  className={classes.notificationLabel}
                                >
                                  {notifType}
                                </MenuItem>
                              );
                            },
                          )}
                        </TextField>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="messageHtml"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                      const reportMessageWarnings = validateReport({
                        t,
                        report: removeHTMLTags(field.value),
                        fpsChecked: isFps,
                        isSilent: notificationType === NotificationType.Silent,
                      });
                      return (
                        <>
                          <RichTextEditor
                            id="field-control-message"
                            data-testid="message"
                            label={t(
                              'addIncidentReport.inputs.description.label',
                            )}
                            initialValue={initialValues.messageHtml}
                            onChange={(html, text) => {
                              field.onChange(html);
                              setValue('messageText', text);
                            }}
                            editable={!formState.isSubmitting}
                            error={!!fieldState.error}
                            required
                            warning={!!reportMessageWarnings.length}
                          />
                          {!!(
                            fieldState.error || reportMessageWarnings.length
                          ) && (
                            <FieldHelperMessages
                              messages={
                                fieldState.error?.message
                                  ? [fieldState.error.message]
                                  : reportMessageWarnings
                              }
                              error={!!fieldState.error}
                              sx={{
                                marginLeft: 1.75,
                                marginRight: 1.75,
                                color: reportMessageWarnings.length
                                  ? 'warning.main'
                                  : undefined,
                              }}
                              data-testid="report-validation-message"
                            />
                          )}
                        </>
                      );
                    }}
                  />
                </Grid>

                <MediaForm
                  geoEvent={geoEvent}
                  latFieldName="lat"
                  lngFieldName="lng"
                  azFieldName="az"
                />

                <Grid item xs={12}>
                  <Box sx={{ paddingTop: 2, paddingBottom: 1 }}>
                    <Typography variant="h3">
                      {t('addIncidentReport.sections.preview')}
                    </Typography>
                  </Box>
                </Grid>

                <ReportNotificationPreview geoEvent={geoEvent} />

                <GeoEventChangelog
                  geoEvent={geoEvent}
                  evacZones={availableZones}
                  zoneStyle={evacZoneStyle}
                />

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.button}
                    disabled={formState.isSubmitting}
                  >
                    {formState.isSubmitting ? (
                      <CircularProgress size={24} />
                    ) : (
                      t('addIncidentReport.button')
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </FormProvider>
    </MapProvider>
  );
};

export default ReportForm;
