import { useMemo } from 'react';
import { LngLat, LngLatBounds } from 'maplibre-gl';
import { AlertCamera, useAlertCameras } from 'hooks/useAlertCameras';
import useMapState from 'state/useMapState';
import { SerializedBounds } from 'shared/types';
import { isTimelapseSupported } from 'components/CameraDetails/CameraDetails.utils';

type CameraOption = AlertCamera & { isInViewPort: boolean };

type UseAlertCameraOptions = {
  cameraOptions: CameraOption[];
  isLoading: boolean;
};

const isAlertCameraInViewPort = (
  camera: AlertCamera,
  bounds?: SerializedBounds,
): boolean => {
  if (!bounds) return false;

  const southWest = new LngLat(
    // eslint-disable-next-line no-underscore-dangle
    (bounds._southWest?.lng || bounds._sw?.lng)!,
    // eslint-disable-next-line no-underscore-dangle
    (bounds._southWest?.lat || bounds._sw?.lat)!,
  );
  const northEast = new LngLat(
    // eslint-disable-next-line no-underscore-dangle
    (bounds._northEast?.lng || bounds._ne?.lng)!,
    // eslint-disable-next-line no-underscore-dangle
    (bounds._northEast?.lat || bounds._ne?.lat)!,
  );
  const bbounds = new LngLatBounds(southWest, northEast);

  return bbounds.contains(new LngLat(camera.latlng.lng, camera.latlng.lat));
};

/**
 * 1. Sort cameras that are in the viewport first.
 * 2. Then sort them by timestamp in descending order.
 */
const sortByViewPortAndImageTimestampDesc = (
  a: CameraOption,
  b: CameraOption,
): number => {
  if (a.isInViewPort && !b.isInViewPort) return -1;
  if (!a.isInViewPort && b.isInViewPort) return 1;
  return b.imageTimestamp.getTime() - a.imageTimestamp.getTime();
};

export const useAlertCameraOptions = (): UseAlertCameraOptions => {
  const { activeMapBounds } = useMapState();
  const { alertCameras, isLoading } = useAlertCameras({
    enabled: true,
    withRealtimeUpdates: false,
  });

  const cameraOptions = useMemo(() => {
    if (!alertCameras) return [];
    const cameras: CameraOption[] = alertCameras
      .filter((camera) => isTimelapseSupported(camera))
      .map((camera) => ({
        ...camera,
        isInViewPort: isAlertCameraInViewPort(camera, activeMapBounds),
      }));
    return cameras.sort(sortByViewPortAndImageTimestampDesc);
  }, [activeMapBounds, alertCameras]);

  return { cameraOptions, isLoading };
};
