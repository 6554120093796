/* eslint-disable i18next/no-literal-string */
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Box, Grid, Tooltip, Typography, Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation, Trans } from 'react-i18next';
import {
  getAcreageStrParts,
  isMobile,
  isWildFireScheduled,
} from 'shared/utils';
import {
  getTimePass,
  getDateFormatted,
  setNaiveISODateStringToUTC,
  formatDate,
  SHORT_MONTH_DAY_12HOUR_FORMAT,
} from 'shared/dates';
import { GeoEvent } from 'shared/types';
import { useSnackbarState } from 'state';
import { differenceInDays } from 'date-fns';
import { NotificationSwitch } from './NotificationSwitch';
import GeoEventContainment from './GeoEventContainment';
import GeoEventStopped from './GeoEventStopped';

type GeoEventInfoProps = {
  geoEvent: GeoEvent;
};

const ABORT_SHARE_ERROR_STRING = 'Share canceled';

const useStyles = makeStyles()((theme: Theme) => ({
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  infoBox: {
    flex: 1,
    padding: '0px 12px',
    margin: '4px 0px',
  },
  infoIcon: {
    width: 12,
    height: 12,
    marginLeft: theme.spacing(0.25),
  },
  verifiedIcon: {
    marginLeft: theme.spacing(0.5),
    marginBottom: -3,
  },
  borderRight: {
    borderRight: '1px solid rgba(0, 0,0, 0.2)',
  },
  upperCaseText: {
    textTransform: 'uppercase',
  },
  button: {
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const GeoEventUpdateTime = (props: GeoEventInfoProps): JSX.Element | null => {
  const { geoEvent } = props;
  const { dateModified } = geoEvent;
  const isScheduled = isWildFireScheduled(geoEvent);
  const { t } = useTranslation();

  if (!geoEvent.isActive) {
    return null;
  }

  if (isScheduled) {
    const prescribedDateStart = geoEvent.data.prescribedDateStart
      ? new Date(setNaiveISODateStringToUTC(geoEvent.data.prescribedDateStart))
      : null;

    const scheduledStart = prescribedDateStart
      ? formatDate(prescribedDateStart, SHORT_MONTH_DAY_12HOUR_FORMAT, true)
      : null;

    return (
      <Typography>
        {t('geoEvent.scheduledStart')} &bull; <b>{scheduledStart}</b>
      </Typography>
    );
  }

  return (
    <Typography>
      {t('geoEvent.updated')}&nbsp;
      <b>{getTimePass(dateModified)}</b>
    </Typography>
  );
};

const GeoEventInfo = (props: GeoEventInfoProps): JSX.Element => {
  const { geoEvent } = props;
  const { classes, cx } = useStyles();
  const { setSnackbar } = useSnackbarState();
  const { t, i18n } = useTranslation();

  const {
    id,
    name,
    dateCreated,
    dateModified,
    data: { acreage, containment, isFps },
    address,
    isActive,
    createdByName,
  } = geoEvent;

  const isScheduled = isWildFireScheduled(geoEvent);

  const handleShareButtonClick = async (): Promise<void> => {
    const ts = new Date(dateModified).getTime();
    const shareUrl = `${import.meta.env.VITE_BASE_SHARE_URL}i/${id}?ts=${ts}`;

    if (!Capacitor.isNativePlatform()) {
      navigator.clipboard.writeText(shareUrl);
      setSnackbar(t('geoEvent.linkCopied'), 'success');
      return;
    }

    const hashTag = `#${name.replace(/\s+/g, '')}`;
    try {
      await Share.share({
        title: name,
        text: address ? `${name} @ ${address} - ${hashTag}` : name,
        url: shareUrl,
        dialogTitle: t('geoEvent.shareIncident'),
      });
    } catch (error) {
      // iOS will throw an error if aborted
      if ((error as Error)?.message === ABORT_SHARE_ERROR_STRING) {
        return;
      }

      setSnackbar(t('geoEvent.errorMessage'), 'error');
    }
  };

  const shareTooltipText = Capacitor.isNativePlatform()
    ? t('geoEvent.share')
    : t('geoEvent.copyLink');

  const canShare = Capacitor.isNativePlatform() || !!navigator.clipboard;

  const [acreageStr] = getAcreageStrParts(
    acreage,
    containment,
    i18n.resolvedLanguage,
  );

  const getStatusText = (): string => {
    if (isScheduled && isActive) {
      return t('geoEvent.scheduled');
    }
    if (isActive) {
      return t('geoEvent.active');
    }
    return t('geoEvent.inactive');
  };

  const postedDaysAgo = isActive
    ? differenceInDays(
        new Date(),
        new Date(setNaiveISODateStringToUTC(dateCreated)),
      ) || null
    : null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={10}>
        <Box className={classes.infoContainer}>
          <Box
            className={cx(classes.infoBox, classes.borderRight)}
            data-testid="acresBox"
          >
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              className={classes.upperCaseText}
            >
              {t('geoEvent.acres')}
            </Typography>
            <Typography align="center">{acreageStr || '-'}</Typography>
          </Box>
          <Box
            className={cx(classes.infoBox, classes.borderRight)}
            data-testid="containmentBox"
          >
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              className={classes.upperCaseText}
            >
              {t('geoEvent.containment')}
            </Typography>
            <GeoEventContainment isFps={isFps} containment={containment} />
          </Box>
          <Box className={classes.infoBox} data-testid="statusBox">
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              className={classes.upperCaseText}
            >
              {t('geoEvent.status')}
            </Typography>
            <Typography align="center">{getStatusText()}</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} data-testid="createdBy">
        {isFps && <GeoEventStopped />}

        <GeoEventUpdateTime geoEvent={geoEvent} />

        <Typography variant="subtitle1" color="textSecondary">
          {postedDaysAgo ? (
            <Trans
              i18nKey="geoEvent.createdByDaysAgo"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
                dateFormatted: getDateFormatted(dateCreated),
                count: postedDaysAgo,
              }}
            >
              Created by <b>Watch Duty</b> • 1 day ago • Aug 4 at 12:54 PM
            </Trans>
          ) : (
            <Trans
              i18nKey="geoEvent.createdBy"
              values={{
                name: createdByName.displayNameShort || t('geoEvent.watchDuty'),
                dateFormatted: getDateFormatted(dateCreated, {
                  includeYear: true,
                }),
              }}
            >
              Created by <b>Watch Duty</b> • Aug 4, 2024 at 12:54 PM
            </Trans>
          )}
        </Typography>
      </Grid>

      {isMobile() && <NotificationSwitch geoEvent={geoEvent} />}

      <Grid item xs={12}>
        {canShare && (
          <Tooltip title={shareTooltipText}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={handleShareButtonClick}
              className={classes.button}
            >
              {t('geoEvent.shareIncident')}
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default GeoEventInfo;
